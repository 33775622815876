.projet-welcome{
    width: 100%;
    height: 190px;
    background-color: #0380b1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.projet-welcome>h2{
    font-size: 37px;
}
.projet-welcome>div{
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.projet-welcome>div>a:first-child{
    color: #b3b3b3;
}
.projet-welcome>div>div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0px 2px;
    background-color: black;
}
.projet-welcome>div>a:last-child{
    color: #000;
}
.projet-msg{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "text image";
    padding: 50px 0px;
}
.projet-msg>div:nth-child(1){
    grid-row: 1/2;
    grid-area: text;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    padding-right: 5%;
}
.projet-msg>div:nth-child(1)>h2{
    font-weight: 600;
    color:#553ab9 ;
    font-size: 40px;
    margin-bottom: 30px;
}
.projet-msg>div:nth-child(1)>p{
    font-size: 20px;
    line-height: 1.7;
    color: rgb(82, 80, 80);
    text-align: justify;
}

.projet-msg>div:nth-child(2){
    grid-row: 1/2;
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
}
.projet-msg>div:nth-child(2)>img{
    width: 400px;
    height: 400px;
}
.projet-para{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 20%;
    position: relative;
}
.projet-para > h2 {
    color: #0380b1;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 20px;
    text-align: center;
}
.projet-para > div {
    color: #393939;
    font-size: 17px;
    /* font-family: Segoe UI Emoji; */
    border: 2px solid rgba(0, 0, 0, 0.5);
    padding: 3%;
    border-radius: 5px;
    line-height: 32px;
    text-align: justify;
}

.projet-para > div p{
    font-size: 20px;
    line-height: 1.7;
    color: rgb(82, 80, 80);
    text-align: justify;
}
.projet-para > div b {
    font-weight: 500;
    color: black;
}

.projet-para > div span {
    padding-left: 10px;
    display: block;
}

.projet-msg>div:nth-child(1)>p>b{
    font-weight: 600;
}

@media screen and (max-width:991px){
    .projet-msg>div:nth-child(2)>img{
        width: 230px;
        height: 230px;
    }
    .projet-msg{
        grid-template-columns: 60% 40%;
    }
}
@media screen and (max-width:800px){
    .projet-msg>div:nth-child(2)>img{
        width: 400px;
        height: 400px;
    }
    .projet-msg>div:nth-child(2){
        margin: 20px 0px;
    }
    .projet-msg{
        grid-template-columns: 100%;
    grid-template-areas: "image" "text";
    }
    .projet-msg>div:nth-child(1){
        margin: 20px 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0px 5%;
    }
    .projet-para{
        padding: 0px 5%;
    }

}
@media screen and (max-width:600px){
    .projet-msg>div:nth-child(2)>img{
        width: 300px;
        height: 300px;
    }
    .projet-welcome>h2{
        font-size: 25px;
    }
    .projet-welcome>div{
        font-size: 16px;
    }
    .projet-para > h2{
        font-size: 22px;
    }

}