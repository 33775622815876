.candidature-welcome{
    width: 100%;
    height: 190px;
    background-color: #0380b1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.candidature-welcome>h2{
    font-size: 37px;
}
.candidature-welcome>div{
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.candidature-welcome>div>a:first-child{
    color: #b3b3b3;
}
.candidature-welcome>div>div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0px 2px;
    background-color: black;
}
.candidature-welcome>div>a:last-child{
    color: #000;
}
.candidature-img {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "image text";
    transition: 0.5s;
    padding: 20px 0px;
  }
  .candidature-img > div:nth-child(1) {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .candidature-img > div:nth-child(1) > img {
    width: 80%;
    height: 520px;
  }
  .candidature-img > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    grid-area: text;
    justify-content: center;
    padding-right: 15px;
    position: relative;
  }
  .candidature-img > div:nth-child(2)>img{
    position: absolute;
    top: 45%;
    left: 50%;
    width: 14px;
    height: 14px;
    animation: cercleAnime 16s linear infinite;
  }
  .candidature-img > div:nth-child(2) > h2 {
    color: #fc73a1;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    text-align: center;
  }
  .titre-cand{
      width: 100%;
      padding: 0px 10%;
      text-align: center;
      color: #393a42;
      font-size: 25px;
  }
  .form-candidature{
    padding: 30px 20%;
    display: flex;
    align-items: center;
    color:#495057;
    flex-direction: column;
}
.form-candidature>form{
   width: 100%;
   transition: 0.5s;
}
.form-candidature>form>button{
    width: 100px;
    margin: auto auto !important;
    padding: 10px;
    background-color: #0380b1 !important;
    color: white;
    border: 1px solid #0380b1;
    border-radius: 5px;
}
.form-candidature>form>button:hover{
    color: #0380b1 !important;
    background-color: white !important;
}
.form-candidature +div{
    width: 80%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
}
.form-candidature +div>img{
    width: 100px;
    height: 100px;
    animation: moveLeftBounce 15s linear infinite;
}

  @media screen and (max-width: 991px) {
    .candidature-img {
        grid-template-columns: 100%;
        grid-template-areas:
          "image"
          "text";
      }
      .candidature-img > div:nth-child(2) {
        grid-area: image;
        align-items: center;
        text-align: center;
        padding: 0px 10%;
      }
      .candidature-img > div:nth-child(1) {
        grid-area: text;
      }
      .candidature-img > div:nth-child(2)>img{
        left: 60%;
      }
      .candidature-img > div:nth-child(1) > img {
        width: 70%;
        height: 500px;
      }
  }
  
@media screen and (max-width:800px){
    .form-candidature{
        padding: 30px 10%;
   }
    }
  @media screen and (max-width: 700px) {
    .candidature-img > div:nth-child(1) > img {
        width: 90%;
      }
      .candidature-img > div:nth-child(2) > h2 {
          font-size: 25px;
      }
      .titre-cand{
        padding: 0px 5%;
        font-size: 20px;
    }

  }
  @media screen and (max-width:600px){
        .form-candidature{
            padding: 30px 5%;
           
       }
       .form-candidature>form{
           padding: 5% !important;
       }
}
  @media screen and (max-width: 500px) {
    .candidature-img > div:nth-child(1) > img {
        width: 95%;
        height: 360px;
      }
      .candidature-img > div:nth-child(2) > h2 {
        font-size: 18px;
    }
    .candidature-welcome>h2{
        font-size: 30px;
    }
    .titre-cand{
        font-size: 18px;
    }
  }
  @media screen and (max-width:450px){
         .form-candidature{
            padding: 30px 0%;
           
       }
    }
   