.peper {
    border: 8px solid #fff;
    margin: 16px;
    padding: 36px 0 0;
    background: rgba(255,255,255,0.9);
    box-shadow: 0px 16px 26px -10px #3f51b599, 0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%);
   
}

.quiz {
    display: flex;
    justify-content: center;
    flex-grow: 1;

    width: 100%;
    /* padding-top: 100px; */

    /* background: linear-gradient(90deg, #5041b2 0%, #7969e6 100%); */
}

.quiz h1 {
    margin-left: 10px;
    color: black;
}

/* .quizWrapper {
    width: 600px;
} */