.tab{
    margin: 5%;
    /* width: 100%; */
    margin-left: 30%;
    margin-right: 10%;
    overflow: hidden;
position: relative;
display: grid;
grid-template-columns: 100%;
}
.tab-tete{
    width: 100%;
    text-align: center;
    font-weight: 600;
    color: #0380b1;
    padding-bottom: 30px;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column: 1/2;
}
.tab-tete>h2{
    text-align: center;
    font-weight: 600;
}
.tab-tete>p{
    font-size: 32px;
}
.map-tab{
    width: 100%;
    padding: 10px;
display: grid;
grid-template-columns: 50% 50%;
place-items: center;
transition: 1.5s ease-in-out;
grid-row-start: 2;
    grid-row-end: 3;
    grid-column: 1/2;
    height: max-content;
}
.map-tab>div{
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
border-radius: 5px;
box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.3);
cursor: pointer;
margin: 20px;
}
.map-tab>div>h3{
    font-weight: 600;
}
.map-tab>div:first-child{
    background-color: #FEE0ED;
    color: #f46485;
}
.map-tab>div:nth-child(2){
    background-color: #fcddc3;
    color: #eaa369;
}
.map-tab>div:nth-child(3){
    background-color: #dad5ee;
    color: #6752b7;
}
.map-tab>div:nth-child(4)>img{
    width: 80px;
}
.map-tab>div:nth-child(4){
    background-color: #c8f1e8;
    color: #30ab92;
}
.map-tab>div:nth-child(5){
    background-color: #c8e4f9;
    color: #0c72c0;
}
.map-tab>div:nth-child(5)>img{
    width: 60px;
    height: 54px;
}
.map-tab>div:nth-child(6){
    background-color: #f7e7b9;
    color: #e67241;
}

.map-tab>div:nth-child(6)>img{
    width: 60px;
    height: 54px;
}

.map-tab>div>img{
    width: 75px;
    height: 70px;
    margin: 10px;
}
.map-tab>div:hover{
   animation: anim1 0.5s linear 1
}
.map-tab>div>h3{
    font-size: 17px;
}
.tab-slide{
    inset: 0;
    width: 100%;
    transition: 1.5s ease-in-out;
    transform: translateX(110%);
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column: 1/2;
}
.tab.slide .map-tab{
    transform: translateX(-110%);
}
.tab.slide .tab-slide{
    transform: translateX(0%);
}

@media screen and (max-width:991px){
    .tab{
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media screen and (max-width:750px){
    .map-tab{
    grid-template-columns: 100%;
    }
}
@media screen and (max-width:600px){
    
    .tab-tete>p{
        font-size: 28px;
    }
}