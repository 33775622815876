/* .ActiveQuiz {
    margin: 0 10px;
    padding: 20px;

    box-sizing: border-box;

    border: 2px solid #0380b1;
    border-radius: 5px;

    color: #0380b1;
} */

.Question {
    display: flex;
    justify-content: space-between;
}