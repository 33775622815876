.collap-conge{
    padding: 10px;
    border:1px solid #550338;
    margin: 15px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 100%;
}
.collap-conge>div:nth-child(1){
    grid-column: 1/2;
    display: grid;
    grid-template-columns: 50% 10% 10% 30%;
}
.collap-conge>div:nth-child(1)>div{
    margin: 5px;
    border-radius: 5px;
}
.collap-conge>div:nth-child(1)>div:nth-child(1){
    background-color: #c4eae2;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    height: 65px;
grid-column: 1/2;
}
.collap-conge>div:nth-child(1)>div:nth-child(1)>p{
    margin: 0px;
    font-weight: 600;
}

.collap-conge>div:nth-child(1)>div:nth-child(2){
    background-color: #efe6ec;
    display: flex;
justify-content: center;
align-items: center;
    height: 65px;
    grid-column: 2/3;
}
.collap-conge>div:nth-child(1)>div:nth-child(2)>img{
width: 35px;
height: 30px;
cursor: pointer;
}
.collap-conge>div:nth-child(1)>div:nth-child(3){

    display: flex;
justify-content: center;
align-items: center;
    height: 65px;
    grid-column: 3/4;
}
.collap-conge>div:nth-child(1)>div:nth-child(3) img{
    width: 33px;
    height: 30px;
    cursor: pointer;
    }
.collap-conge>div:nth-child(1)>div:nth-child(4){
    display: flex;
justify-content: center;
flex-direction: column;
    padding: 10px;
    height: 65px;
    grid-column: 4/5;
    font-size: 20px;
}
.collap-conge>div:nth-child(1)>div:nth-child(4) p{
    margin: 0px;
}
.collap-conge>div:nth-child(1)>div:nth-child(4)>div{
    display: flex;
    justify-content: space-between;
align-items: center;
}
.collap-conge>div:nth-child(1)>div:nth-child(4)>div>div{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 25px;
    height: 25px;
}
.collap-conge>div:nth-child(1)>div:nth-child(4)>div>div>i{
    font-size: 15px;
}

.collap-conge>div:nth-child(2){
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    background-color: #efe6ec;
    margin: 5px;
    font-size: 20px;
}
.collap-conge>div:nth-child(2)>p{
    font-weight: 600;
}
.modal-backdrop.show{
    opacity: 0;
}
.yes-delete{
    background-color: #0380b1 !important;
}
.no-delete{
    background-color: white !important;
}
@media screen and (max-width:700px){
    .collap-conge>div:nth-child(1)>div:nth-child(4){
        font-size: 14px;
    }
    .collap-conge>div:nth-child(1)>div:nth-child(1){
        font-size: 14px;
    }
    .collap-conge>div:nth-child(1)>div:nth-child(2)>img{
        width: 25px;
        height: 20px;
        }
        .collap-conge>div:nth-child(1)>div:nth-child(3) img{
            width: 23px;
            height: 20px;
            }
            .collap-conge>div:nth-child(1)>div:nth-child(4)>div>div{
                width: 20px;
                height: 20px;
            }
            .collap-conge>div:nth-child(1)>div:nth-child(4)>div>div>i{
                font-size: 12px;
            }
            .collap-conge>div:nth-child(2){
                font-size: 14px;
            }
            
}
@media screen and (max-width:550px){
    .collap-conge>div:nth-child(1){
        grid-column: 1/2;
        display: grid;
        grid-template-columns: 64% 18% 18%;
    }    
    .collap-conge>div:nth-child(1)>div:nth-child(4){
      grid-row: 2/3;
      grid-column: 1/2;
    }
    .collap-conge>div:nth-child(2){
        grid-row: 3/4;
    }
    .collap-conge{
        margin: 10px;
    }
}
