.navb{
        margin: 0px;
       position:fixed;
       top:0px;
       right: 0px;
       left: 0px;
       z-index:10;
       transition: all 0.4s linear;
    width: 100%;
    background:  white;
    font-family: Microsoft PhagsPa;
}
.navb>.row>div{
    height: 100%;
}
.second{
    display: flex;
    align-items: center;
    justify-content: center
}
.second a{
    font-size: 16px;
    color:#393939;
    font-family: Microsoft PhagsPa;
    font-weight: bold;
}
.drop{
    margin: 0px 20px;
   
}

.second span{
    font-size: 16px;
    color:#393939;
    font-family: Microsoft PhagsPa;
    font-weight: bold;
cursor: pointer;
}
.drop>div{
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.6);
-webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.6);
-moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.6);
z-index: 10;
background-color:   white;
display: flex;
justify-content: flex-end;
align-items: center;
}
.drop li{
border-bottom: 1px solid  #c7c6c6;
padding: 10px;
display: flex;
align-items: center;
text-transform: uppercase;
font-size: 14px;
    font-weight: 600;
    color:  #5E5E5E;
    margin: 0px 5px;
}
.drop i{
    font-size: 16px;
    margin-left: 3px;
}
.second a:hover{
    color: #0380b1 !important
}
.pad{
    padding-left: 80px !important;
    transition: all 0.4s linear;
}
.icon>i{
    cursor:pointer
}
.ul_drop span{
    color:  #393939;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.ul_drop>li>a{
    color:  #393939;
    font-weight: 600;
    font-size: 16px;
}
.ul_drop span:hover{
    color:  #0380b1 !important ;
}
.ul_drop a:hover{
    color:  #0380b1 !important ;
}
.ul_drop{
    line-height: 25px;
}

.login-user{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #393939;
    font-size: 20px;
}

.login-user{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5E5E5E;
    font-size: 20px;
}

.user-avatar{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.drop-user button{
    margin: 7px 0px;
    outline:none;

   display: flex;
    align-items: center;
    justify-content: center;
    

    color: white;
  font-family: Franklin Gothic Demi;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  padding: 5px 18px;
  background-color: #0380b1;
  border-radius: 25px;

  border: 1px solid #0380b1;
}
.drop-user button:hover{
    background-color: white;
    color:#0380b1
}
.dropdown-menu.show{
    box-shadow: 0px 0px 5px 1px rgba(0,0,0, 0.1);
    border-radius: 10px;
}

@media screen and (max-width:550px){
    .pad{
        padding-left: 20px !important;
    }
}