.offrePage-welcome{
    width: 100%;
    height: 190px;
    background-color: #0380b1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.offrePage-welcome>h2{
    font-size: 37px;
}
.offrePage-welcome>p{
    font-size: 17px;
    text-align: center;
    padding: 0px 5%;
}

.offre-title{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 30px;
   
} 
.offre-title>h3{
    text-align: center;
    color:#0380b1 ;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 5px;
    position: relative;
}
.offre-title>h3::after {
    content: "";
    position: absolute;
    bottom: -5px;
    width: 50px;
    height: 3px;
    left: 0px;
    background-color: #b3b3b3;
  }
  .offre-title>h3::before {
    content: "";
    position: absolute;
    bottom: -5px;
    width: 50px;
    height: 3px;
    left: 52px;
    background-color: #000000;
  }
.offre-desc{
    color: #393939 ;
    padding: 30px 7% 0px 7%;
    line-height: 1.5;
    font-size: 20px;
    white-space: break-spaces
}

.offre-detail{
    width: 100%;
    color: #393939 ;
    padding: 30px 7% 0px 7%;
    line-height: 2;
    font-size: 18px;
    margin: 0px;
}
.offre-detail div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px 20px 0px 10px;
    border-right: 1px solid #d3d2d2;
    border-bottom: 1px solid  #d3d2d2;
}
.offre-detail h4{
    font-weight: 500;
    color: #181818;
}
.expiration{
    margin: 30px 10% 0px 8%;
    color:#393939;
    font-size: 20px;
}
.expiration i{
    margin-right: 7px;
}

    @media screen and (max-width:600px){
        .offre-title>h3{
           font-size: 20px;
                
            }
    }

       