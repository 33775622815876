.dash-bar{
    width: 100%;
    height: 90px;
    background-color: #0380b1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
position: relative;
}
.dash-bar>h2{
    font-weight: 600;
}
.dash-bar >.dropdown{
    position: absolute;
    top:-40px;
    right: 5%;
    width: 80px;
    height: 80px;
    z-index: 11;
}
.dash-bar >.dropdown .dropdown-menu.show{
    transform: translate3d(-105px, 80px, 0px) !important;
}
.dash{
    position: relative;
    width: 100%;
    min-height: 60vh;
}
.slide-dash{
    position: absolute;
    left: 0px;
    top: 190px;
    /* height: 200px;
    width: 300px; */
    transition: 1.2s ease-in-out;
    transform: translateX(-110%);
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 5px 6px rgba(0,0,0, 0.1);
    z-index: 2;
    background-color: #fff;
}
.slide-dash>div{
    display: flex;
    align-items: center;
    padding: 10px 20px;
font-size: 19px;
font-weight: 600;
cursor: pointer;
position: relative;
overflow: hidden;
}
.slide-dash>div:not(:first-child)::after{
content: "";
position: absolute;
height: 3px;
width: 100%;
background-color: #0380b1;
bottom: 0px;
left: 0px;
transform: translateX(-100%);
transition: 1s ease-in-out;
}
.slide-dash>div:not(:first-child):hover::after{
    transform: translateX(0%);
}
.slide-dash>div>img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
.slide-dash>div>p{
    margin: 0px;
}
.slide-dash>div:first-child{
    border-bottom: 3px solid #0380b1;
    color: #0380b1;
    font-size: 22px;
    justify-content: center;
    padding-top: 0px;
    cursor: initial;
}

.slide-dash-hover{
    position: absolute;
    left: 0px;
    top: 190px;
    transition: 1.2s ease-in-out;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 5px 6px rgba(0,0,0, 0.1);
transition-delay: 0.7s;
z-index: 2;
background-color: #fff;
}
.slide-dash-hover>div{
    display: flex;
    align-items: center;
    padding: 10px 20px;
font-size: 19px;
font-weight: 600;
cursor: pointer;
}
.slide-dash-hover>div>img{
    width: 50px;
    height: 50px;
}

.slide-dash-hover.active-hover{
    transform: translateX(-110%);
    transition-delay: 0s
}
.slide-dash.active-hover,.slide-dash.active-hover{
    transform: translateX(0%);
    transition-delay: 0.7s;
}
@media screen and (max-width:991px){
    .dash-bar> .dropdown{
       display: none;
    }
}
@media screen and (max-width:991px){
    .slide-dash-hover{
        padding: 0px;
    }
    .slide-dash-hover>div{
        padding: 0px;
    }
    .slide-dash{
        padding: 0px;
    }
    .slide-dash>div:first-child{
        padding-top: 10px;
    }
}