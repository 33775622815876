.contact-welcome{
    width: 100%;
    height: 190px;
    background-color: #0380b1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-welcome>h2{
    font-size: 37px;
}
.contact-welcome>div{
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-welcome>div>a:first-child{
    color: #b3b3b3;
}
.contact-welcome>div>div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0px 2px;
    background-color: black;
}
.contact-welcome>div>a:last-child{
    color: #000;
}
.cord{
    width: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cord{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5%;
    position: relative;
}
.cord > h2 {
    color: #0380b1;
    font-size: 22px;
    font-weight: 600;
    position: relative;
    margin-bottom: 20px;
  }
  .cord>div{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 5%;
      flex-wrap: wrap;
  }
  .cord>div>div{
      width: 300px;
      height: 295px;
      margin: 15px 20px;
      background-color: #efe6ec;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0 0 30px rgb(56 46 46 / 10%);
      padding: 20px;
      padding-top: 0px;
      
  }
  .cord>div>div>img{
      width: 120px;
      height: 125px;
  }
  .cord>div>div>h4{
      color: #0380b1;
      font-weight: 600;
      margin: 10px 0px;
  }
  .cord>div>div>p{
      font-size: 20px;
      font-weight: 500;
  }
.formu{
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0px 20%;
    width: 100%;
}
.formu>h2{
    color: #0380b1;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.form-contact{
    padding-bottom: 30px;
    /* display: flex;
    justify-content: center; */
    color:black;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form-contact>form{
    width: 100%;
    transition: 0.5s;
}
.form-contact>form>button{
    width: 130px;
    margin: auto auto !important;
    padding: 12px;
    background-color: #0380b1 !important;
    color: white;
    border-radius: 10px;
    border: 1px solid transparent;
}
.form-contact>form>button:hover{
    color: #0380b1 !important;
    background-color: white !important;
border: 1px solid #0380b1;
}
.form-control{
    background-color: #e4edf8;
}
.contact-alert{
    position: fixed;
    top: 23%;
    right: 10px;
    border-radius: 6px;
    background:  #37c95c;
    color: white;
    padding: 7px;
    font-size: 20px;
    transition: 0.5s;
}
.erreur-form{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
    border-radius: 5px;
    padding: 5px;
}
.erreur-form>p{
    text-align: center;
    color: black;
    margin:0px;
}
.formu +div{
    width: 80%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
}
.formu +div>img{
    width: 100px;
    height: 100px;
    animation: moveLeftBounce 15s linear infinite;
}


    @media screen and (max-width:800px){
        .formu{
            padding: 30px 10%;
       }
        }
      @media screen and (max-width:600px){
            .formu{
                padding: 30px 5%;
               
           }
    }
    @media screen and (max-width:500px){
        .form-contact{
           padding: 0px;
        }
        .cord > h2 { 
            font-size: 17px;
          }
          .form-contact>form{
              padding: 5% !important;
          }
          .erreur-form {
              width: 90%;
          }
        }
      @media screen and (max-width:450px){
             .formu{
                padding: 30px 0%;
               
           }
        }