.collap-auto {
    padding: 10px;
    border: 1px solid #550338;
    margin: 15px;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 100%;
}

.collap-auto>div:nth-child(1) {
    grid-column: 1/2;
    display: grid;
    grid-template-columns: 85% 15%;
}

.collap-auto>div:nth-child(1)>div {
    margin: 5px;
    border-radius: 5px;
}

.collap-auto>div:nth-child(1)>div:nth-child(1) {
    background-color: #c4eae2;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    grid-column: 1/2;
}

.collap-auto>div:nth-child(1)>div:nth-child(1)>p {
    margin: 0px;
    font-weight: 600;
}

.collap-auto>div:nth-child(1)>div:nth-child(2) {
    background-color: #efe6ec;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 2/3;
}

.collap-auto>div:nth-child(1)>div:nth-child(2)>img {
    width: 35px;
    height: 30px;
    cursor: pointer;
}

.collap-auto>div:nth-child(1)>div:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-column: 3/4;

}

.collap-auto>div:nth-child(1)>div:nth-child(3)>div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    background-color: #efe6ec;
    width: 100%;
    margin: 5px;
    border-radius: 3px;
    font-weight: 600;
}

.collap-auto>div:nth-child(1)>div:nth-child(3)>div>img {
    width: 40px;
    height: 40px;
}



.collap-auto>div:nth-child(2) {
    grid-row: 2/3;
    margin: 5px;
    font-size: 20px;
    display: grid;
    grid-template-columns: 100%;
}

.collap-auto>div:nth-child(2)>div:nth-child(1) {

    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    background-color: #efe6ec;
    grid-column: 1/2;
}

.collap-auto>div:nth-child(2)>div:nth-child(1)>p {
    font-weight: 600;
}

.collap-auto>div:nth-child(2)>div:nth-child(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    height: 65px;
    grid-column: 2/3;
    font-size: 20px;
}

.collap-auto>div:nth-child(2)>div:nth-child(2) p {
    margin: 0px;
}

.collap-auto>div:nth-child(2)>div:nth-child(2)>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collap-auto>div:nth-child(2)>div:nth-child(2)>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 25px;
    height: 25px;
}

.collap-auto>div:nth-child(2)>div:nth-child(2)>div>div>i {
    font-size: 15px;
}

.modal-backdrop.show {
    opacity: 0;
}

.yes-delete {
    background-color: #0380b1 !important;
}

.no-delete {
    background-color: white !important;
}

@media screen and (max-width:700px) {
    .collap-auto>div:nth-child(2)>div:nth-child(2) {
        font-size: 16px;
    }

    .collap-auto>div:nth-child(1)>div:nth-child(1) {
        font-size: 16px;
    }

    .collap-auto>div:nth-child(1)>div:nth-child(2)>img {
        width: 30px;
        height: 25px;
    }

    .collap-auto>div:nth-child(1)>div:nth-child(3) img {
        width: 30px;
        height: 30px;
    }

    .collap-auto>div:nth-child(2)>div:nth-child(2)>div>div {
        width: 20px;
        height: 20px;
    }

    .collap-auto>div:nth-child(2)>div:nth-child(2)>div>div>i {
        font-size: 12px;
    }

    .collap-auto>div:nth-child(2)>div:nth-child(1) {
        font-size: 16px;
    }

}

@media screen and (max-width:550px) {
    .collap-auto>div:nth-child(1)>div:nth-child(3)>div>img {
        width: 30px;
        height: 30px;
    }

    .collap-auto>div:nth-child(1) {
        grid-template-columns: 80% 20%;
    }

    .collap-auto>div:nth-child(2) {
        grid-template-columns: 100%;
        grid-template-areas: "valid"
            "text";
    }

    .collap-auto>div:nth-child(2)>div:nth-child(2) {
        grid-area: valid;
        height: 2px;
    }

    .collap-auto>div:nth-child(2)>div:nth-child(1) {
        grid-area: text;
    }

    .collap-auto {
        margin: 10px;
    }
}