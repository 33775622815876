.AnswerItem {
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid rgb(3, 128, 177);
    border-radius: 5px;
    cursor: pointer;

}

.AnswerItem:hover {
    box-shadow: 0 0 5px 0 #0380b1 inset, 0 0 10px 1px #0380b1;
    border: 1px solid #0380b1;
    /* transition: rgba(255, 255, 255, .2) .3s ease-in-out; */
}

.AnswerItemSuccess {
    margin-bottom: 10px;
    padding: 15px;
    border: 1px solid #57f076b3;
    border-radius: 5px;
    cursor: pointer;

}

.AnswerItemSuccess:hover {
    box-shadow: 0 0 5px 0 #57f076b3 inset, 0 0 10px 1px #57f076b3;
    border: 1px solid #57f076b3;
}

/* .AnswerItem.success {
    background: rgba(87, 240, 118, 0.7)
}

.AnswerItem.error {
    background: rgba(240, 87, 108, .7);
} */