.btn-acc{
    width: 130px;
    margin: auto auto !important;
    padding: 12px;
    background-color: #0380b1 !important;
    color: white;
    border-radius: 10px;
    border: 1px solid transparent;
}
.btn-acc:hover{
    color: #0380b1 !important;
    background-color: white !important;
border: 1px solid #0380b1;
}

.file-img input{
    display: none;
}
.file-img i{
    cursor: pointer;
    font-size: 25px;
    color: rgb(82, 82, 82);
    position: absolute;
    right: 0px;
    bottom: 20px;
}

.account-alert{
    position: fixed;
    top: 23%;
    right: 10px;
    border-radius: 6px;
    background:  #37c95c;
    color: white;
    padding: 7px;
    font-size: 20px;
    transition: 0.5s;
}

@media screen and (max-width:720px){
    .mp-confirm{
    font-size: 20px;
    font-weight: 600;
}
.mp{
    left: 20% !important;
    right: 20% !important;
}
}
@media screen and (max-width:550px){
    .mp{
   right: 5% !important;
   left: 5% !important;
}
.mp .btn{
padding: 10px 20px;
}
}