.App{
  min-height:100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.App_center{ 
  width: 100%;
  flex: 1 0 auto;
  background-color: #fff;
  position: relative;
}
.top-arrow{
  position: fixed;
  border-radius: 50%;
  background-color: #0380b1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  top: 0%;
  right: 15px;
  z-index: 1;
  opacity: 0;
  overflow: hidden;
color: white;
font-size: 19px;
transition: 0.9s linear;
cursor: pointer;
}