.welcome-home {
  background-color: #eef5f7;
  margin: 0px;
  width: 100%;
  position: relative;
  padding-bottom: 25.5%;
  overflow: hidden;
}

.welcome-home > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 30px 0px;
}
.welcome-home > div:first-child > h2 {
  color: #0380b1;
  font-family: Franklin Gothic Demi;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}
.welcome-home > div:first-child > h2 > span {
  color: #000000;
  font-family: Eras Demi ITC;
  font-size: 30px;
}
.welcome-home > div:first-child >div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.welcome-home > div:first-child >div> a {
  color: white;
  font-family: Franklin Gothic Demi;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 10px 28px;
  background-color: #0380b1;
  border-radius: 30px;
  display: block;
  width: max-content;
  border: 1px solid #0380b1;
  margin: 0px 10px;
}
.welcome-home > div:first-child >div> a:hover {
  color: #0380b1;
  background-color: white;
}
.welcome-home > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
  padding: 0px;
}
.welcome-home > div:nth-child(2) > img:first-child {
  width: 66%;
  height: 500px;
}
.welcome-home > img:nth-child(3) {
  position: absolute;
  bottom: -8.5%;
  width: 100%;
}
.welcome-home > div:first-child > img:nth-child(3) {
  position: absolute;
  bottom: -10%;
  left: 20%;
  width: 100px;
  height: 100px;
  animation: moveLeftBounce 6s linear infinite;
}
@keyframes moveLeftBounce {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(50%, 0);
  }

  80% {
    transform: translate(80%, 0);
  }
}

.welcome-home > div:first-child > img:nth-child(4) {
  position: absolute;
  top: 3.5%;
  right: 10%;
  width: 100px;
  height: 100px;
  animation: moveLeftBounce 6s alternate infinite;
}
.welcome-home > div:first-child > img:nth-child(5) {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  animation: rotate3d 6s linear infinite;
}
@keyframes rotate3d {
  0% {
    transform: rotateY(0);
  }

  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.welcome-home > div:first-child > img:nth-child(6) {
  position: absolute;
  bottom: -10%;
  right: 10%;
  width: 70px;
  height: 70px;
  animation: moveBounce 4s alternate infinite;
}
@keyframes moveBounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(-60px);
  }
}
.welcome-home > div:nth-child(2) > img:nth-child(2) {
  position: absolute;
  bottom: -10%;
  right: 30%;
  width: 70px;
  height: 70px;
  animation: moveRightBounce 6s linear infinite;
}
@keyframes moveRightBounce {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-50%, 0);
  }

  80% {
    transform: translate(-80%, 0);
  }
}
.competence {
  margin: 20px 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 0px;
  transform: translateY(-60px);
}
.competence > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 10%;
  /* margin-top: 30px; */
  justify-content: center;
}
.competence > div:nth-child(1) > h2 {
  color: #0380b1;
  font-size: 32px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.competence > div:nth-child(1) > h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 0px;
  background-color: #b3b3b3;
}
.competence > div:nth-child(1) > h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 52px;
  background-color: #000000;
}
.competence > div:nth-child(1) > a {
  color: white;
  font-family: Franklin Gothic Demi;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 10px 28px;
  background-color: #0380b1;
  border-radius: 30px;
  display: block;
  width: max-content;
  border: 1px solid #0380b1;
}
.competence > div:nth-child(1) > a:hover {
  color: #0380b1;
  background-color: white;
}
.competence > div:nth-child(1) > p {
  color: #393939;
  font-size: 20px;
  /* font-family: Segoe UI Emoji; */
  text-align: justify;
}
.competence > div:nth-child(2) {
  padding: 0px;
  display: flex;
  justify-content: flex-end;
}
.competence > div:nth-child(2) > img {
  width: 70%;
  height: 100%;
}

.services {
  margin: 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 0px;
  padding-bottom: 110px;
}
.services > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 10%;
  margin-top: 20px;
  position: relative;
}
.services > div:nth-child(1) > h2 {
  color: #0380b1;
  font-size: 32px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.services > div:nth-child(1) > h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 0px;
  background-color: #b3b3b3;
}
.services > div:nth-child(1) > h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 52px;
  background-color: #000000;
}

.services > div:nth-child(1) > p {
  color: #393939;
  font-size: 20px;
  text-align: justify;
  /* font-family: Segoe UI Emoji; */
}
.services > div:nth-child(1) > img:nth-child(3) {
  width: 80%;
  height: 400px;
}
.services > div:nth-child(2) {
  position: relative;
}
.services > div:nth-child(2) > div:nth-child(1) {
  margin: 0px;
  width: 100%;
}
.services > div:nth-child(2) > div:nth-child(1) > div > div {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 0 30px rgb(56 46 46 / 20%);
  border: 2px solid transparent;
  cursor: pointer;
}
.services > div:nth-child(2) > div:nth-child(1) > div > div > img {
  width: 100px;
  height: 100px;
}
.services > div:nth-child(2) > div:nth-child(1) > div > div > h4 {
  color: #625d87;
  font-family: Candara;
  font-weight: 600;
}
.services > div:nth-child(2) > div:nth-child(1) > div > div > p {
  color: #726d9f;
  font-family: Segoe UI Emoji;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div {
  background-color: #dad5ee;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) > div:hover {
  background-color: #fff;
  border: 2px dotted #dad5ee;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div {
  margin: 30px 0px;
  background-color: #f1c8da;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:hover {
  background-color: #fff;
  border: 2px dotted #f1c8da;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(3) > div {
  background-color: #c4eae2;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(3) > div:hover {
  background-color: #fff;
  border: 2px dotted #c4eae2;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div {
  margin: 30px 0px;
  background-color: #f8d4b5;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div:hover {
  background-color: #fff;
  border: 2px dotted #f8d4b5;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(5) > div {
  background-color: #ffeebc;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(5) > div:hover {
  background-color: #fff;
  border: 2px dotted #ffeebc;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(6) > div {
  background-color: #bbe1ff;
  margin: 30px 0px;
}
.services > div:nth-child(2) > div:nth-child(1) > div:nth-child(6) > div:hover {
  background-color: #fff;
  border: 2px dotted #bbe1ff;
}
.services > div:nth-child(1) > img:nth-child(4) {
  position: absolute;
  top: 30px;
  right: 50px;
  width: 14px;
  height: 14px;
  animation: cercleAnime 16s linear infinite;
}
@keyframes cercleAnime {
  0%,
  100% {
    transform: translate(0);
  }
  20% {
    transform: translate(-100px, 50px);
  }
  40% {
    transform: translate(-180px, 0px);
  }
  60% {
    transform: translate(-120px, -40px);
  }
  80% {
    transform: translate(-40px, -20px);
  }
}
.services > img:last-child {
  position: absolute;
  bottom: 0px;
  left: 55%;
  width: 100px;
  height: 100px;
  animation: moveLeftBounce 27s linear infinite;
}
.rejoindre {
  display: grid;
  width: 100%;
  grid-template-areas: "image text";
  transition: 0.5s;
  padding: 20px 0px;
}
.rejoindre > div:nth-child(1) {
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rejoindre > div:nth-child(1) > img {
  width: 70%;
  height: 450px;
}
.rejoindre > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 10%;
  grid-area: text;
  justify-content: center;
  padding-right: 15px;
}
.rejoindre > div:nth-child(2) > h2 {
  color: #0380b1;
  font-size: 32px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.rejoindre > div:nth-child(2) > h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 0px;
  background-color: #b3b3b3;
}
.rejoindre > div:nth-child(2) > h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 52px;
  background-color: #000000;
}
.rejoindre > div:nth-child(2) > a {
  color: white;
  font-family: Franklin Gothic Demi;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 10px 28px;
  background-color: #0380b1;
  border-radius: 30px;
  display: block;
  width: max-content;
  border: 1px solid #0380b1;
}
.rejoindre > div:nth-child(2) > a:hover {
  color: #0380b1;
  background-color: white;
}
.rejoindre > div:nth-child(2) > p {
  color: #393939;
  font-size: 20px;
  text-align: justify;
  /* font-family: Segoe UI Emoji; */
}
.valeur {
  margin: 0px;
  padding: 20px 0%;
  width: 100%;
}
.valeur > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  padding-left: 10%;
  padding-right: 15px;
  margin-bottom: 10px;
}
.valeur > div:nth-child(1) > h2 {
  color: #0380b1;
  font-size: 32px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.valeur > div:nth-child(1) > h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 0px;
  background-color: #b3b3b3;
}
.valeur > div:nth-child(1) > h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 52px;
  background-color: #000000;
}
.valeur > div:nth-child(1) > p {
  color: #393939;
  font-size: 20px;
  text-align: justify;
  /* font-family: Segoe UI Emoji; */
}
 .valeur > div:nth-child(2)  {
  margin: 0px;
  width: 100%;
  padding: 0px;
}
.valeur > div:nth-child(2)>div  {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.valeur > div:nth-child(2)>div>div  {
  padding: 20px 10px;
  color: #3b3b3b;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    min-height: 380px;
    box-shadow: 0 0 10px rgb(56 46 46 / 10%);
border: 1px solid #E3E3E3;
}
.valeur > div:nth-child(2)>div>div>h3  {
  position: relative;
  color: #0380b1;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    z-index: 3;
    text-align: center;
  transition: all 0.2s ease;

}
.valeur > div:nth-child(2)>div>div>p  {
  text-align: center;
  opacity:  0;
  position: relative;
  z-index: 3;
  margin: 0px;
  transition: all 0.4s ease;
 

}
.valeur > div:nth-child(2)>div>div:after {
  content: "";
  position: absolute;
  background-color: #f8f8f8;
  inset:0;
  transition: all 0.4s ease;
transform: translateY(110%);
z-index: 1;
}
.valeur > div:nth-child(2)>div>div:hover:after {
  transform: translateY(0px);
}

.valeur > div:nth-child(2)>div>div:hover>p {
  opacity: 1;
  transition-delay: 0.2s;
}
.valeur > div:nth-child(2)>div>div:hover {
  border: 1px solid #E3E3E3;
}
/* .valeur > div:nth-child(2)>div>div::before {
  position: absolute;
    content: '';
    background: linear-gradient(to bottom, rgba(51, 51, 51, 0.75), rgba(51, 51, 51, 0));
    width: 100%;
    left: 0px;
    top: 0px;
    height: 30%;
} */

.valeur > div:nth-child(1) > img:last-child {
  position: absolute;
  top: 10%;
  left: 20%;
  width: 60px;
  height: 45px;
  animation: rot 30s linear infinite;
}
@keyframes rot {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(130px, -20%);
  }
  20% {
    transform: translate(150px, -40%);
  }
  30% {
    transform: translate(170px, -60%);
  }
  40% {
    transform: translate(190px, -80%);
  }
  50% {
    transform: translate(260px, -80%);
  }
  60% {
    transform: translate(270px, -60%);
  }
  70% {
    transform: translate(290px, -40%);
  }
  80% {
    transform: translate(300px, -20%);
  }
  90% {
    transform: translate(320px, -10%);
  }
  100% {
    transform: translate(0px, 0%);
  }
}

.engagement{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    padding-left: 10%;
    padding-right: 6%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.engagement  > h2 {
  color: #0380b1;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  margin-bottom: 30px;
}
.engagement  > h2::after {
  content: "";
  position: absolute;
  bottom: -7px;
  width: 50px;
  height: 3px;
  left: 0px;
  background-color: #b3b3b3;
}
.engagement  > h2::before {
  content: "";
  position: absolute;
  bottom: -7px;
  width: 50px;
  height: 3px;
  left: 52px;
  background-color: #000000;
}
.engagement>p{
  color: #393939;
    font-size: 20px;
    text-align: justify;
    /* font-family: Segoe UI Emoji; */
}
.engagement>p>span{
  font-weight: 600;
}
.engagement>p:last-child{
  padding-left: 10px;
}


.partenaire {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding:20px 10%;
  position: relative;
}
.partenaire > h2 {
  color: #0380b1;
  font-size: 32px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
}
.partenaire > h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 0px;
  background-color: #b3b3b3;
}
.partenaire > h2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 50px;
  height: 3px;
  left: 52px;
  background-color: #000000;
}
.partenaire > div {
  margin: 0px;
  width: 100%;
  padding: 10px;
}
.partenaire > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.partenaire > div > div>div {
 border: 1px solid black;
 border-radius: 5px;
 width: 80%;
 height: 120px;
 display: flex;
 justify-content: center;
 align-items: center;
 transition: 0.5s;
background-color: #fff;
}
.partenaire > div > div>div >a> img {
  width: 100px;
  height: 100px;
}.partenaire > div > div>div >a> img:hover {
 animation: anim1 0.5s linear 1 ;
}
@keyframes anim1{
  0%,100%{
    transform: translatey(0px);
  }
  50%{
transform: translateY(-10px);
  }
}
@media screen and (max-width: 1200px) {
  .welcome-home > div:nth-child(2) > img:nth-child(1) {
    width: 75%;
    height: 500px;
  }
}
@media screen and (max-width:1199px){
  .valeur > div:nth-child(2)>div>div  {
      min-height: 300px;
       margin: 0px 10%;
       padding: 20px;
  }
}
@media screen and (max-width: 991px) {
  .welcome-home > div:nth-child(2) > img:nth-child(1) {
    width: 60%;
    height: 500px;
  }
  .welcome-home {
    padding-bottom: 5.5%;
  }
  .welcome-home > div:first-child > img:nth-child(3) {
    display: none;
  }
  .welcome-home > div:first-child > img:nth-child(4) {
    display: none;
  }
  .welcome-home > div:first-child > img:nth-child(5) {
    display: none;
  }
  .welcome-home > div:first-child > img:nth-child(6) {
    display: none;
  }
  .welcome-home > div:nth-child(2) > img:nth-child(2) {
    display: none;
  }
  .competence{
    margin-top: 20px;
    transform: translateY(0px);
  }

  .competence > div:nth-child(2) {
    display: none;
  }
  .competence > div:nth-child(1) {
    padding: 0px 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .services > div:nth-child(1) {
    padding: 0px 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .services > div:nth-child(1) > img:nth-child(3) {
    width: 60%;
  }
  .services > img:last-child {
    display: none;
  }
  .services {
    padding-bottom: 20px;
  }
  .rejoindre {
    grid-template-areas:
      "image"
      "text";
  }
  .rejoindre > div:nth-child(2) {
    grid-area: image;
    align-items: center;
    text-align: center;
    padding: 0px 10%;
    padding-bottom: 15px;
  }
  .rejoindre > div:nth-child(1) {
    grid-area: text;
  }
  .rejoindre > div:nth-child(1) > img {
    width: 65%;
  }
  
  .valeur > div:nth-child(1) {
    align-items: center;
    padding: 0px 10%;
    text-align: center;
  }
 
  .valeur > div:nth-child(1) > img:last-child {
   display: none;
  }
  .partenaire{
    align-items: center;
  }
  .engagement{
    padding: 0px 7%;
    align-items: center;
  }


}
@media screen and (max-width: 767px) {
  .valeur > div:nth-child(2)>div>div  {
     margin: 0px 20%;
}
}

@media screen and (max-width: 700px) {
  .welcome-home > img:nth-child(3) {
    bottom: -4.5%;
  }
  .services > div:nth-child(1) > img:nth-child(3) {
    width: 70%;
  }
  .rejoindre > div:nth-child(1) > img {
    width: 70%;
  }
  .engagement>h2{
    font-size: 20px;
  }
 
}
@media screen and (max-width: 600px) {
  .welcome-home > div:nth-child(2) > img:first-child {
    width: 85%;
    height: 500px;
  }
  .services > div:nth-child(1) > img:nth-child(3) {
    width: 80%;
  }
  .rejoindre > div:nth-child(1) > img {
    width: 80%;
    height: 400px;
  }
  
}
@media screen and (max-width: 500px) {
  .welcome-home > div:first-child > h2 {
    font-size: 30px;
  }
  .services > div:nth-child(1) > img:nth-child(3) {
    width: 90%;
  }
  .rejoindre > div:nth-child(1) > img {
    width: 90%;
    height: 400px;
  }
  .valeur > div:nth-child(2)>div>div  {
    margin: 0px 10%;
}
  
 
}
@media screen and (max-width: 400px) {
  .welcome-home > div:first-child > h2 {
    font-size: 25px;
  }
  .welcome-home > div:first-child > h2 > span {
    font-size: 22px;
  }
  .welcome-home > div:nth-child(2) > img:first-child {
    width: 90%;
    height: 350px;
  }
  .services > div:nth-child(1) > img {
    height: 320px;
  }
  .services > div:nth-child(1) > img:nth-child(3) {
    height: 350px;
  }
  .rejoindre > div:nth-child(1) > img {
    height: 350px;
  }
 
  .welcome-home > div:first-child >div{
    flex-direction: column;
  }
  .welcome-home > div:first-child >div> a {
    margin: 10px 0px;
  }
  .engagement>h2{
    font-size: 19px;
  }
  .engagement>p{
    font-size: 17px;
  }
  .valeur > div:nth-child(2)>div>div  {
    margin: 0px 5%;
}
}
