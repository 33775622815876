.recover-alert{
    position: fixed;
    top: 24%;
    right: 10px;
    border-radius: 6px;
    background:  #37c95c;
    color: white;
    padding: 7px;
    font-size: 20px;
    transition: 0.5s;
    /* width: 70%; */
}
.login-bar{
    width: 100%;
    height: 90px;
    background-color: #0380b1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
position: relative;
}
.login-bar>h2{
    font-weight: 600;
}
.login-bar>img{
    position: absolute;
    top:-40px;
    right: 5%;
    width: 80px;
    height: 80px;
    z-index: 11;
}
.img-div{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.img-div>img{
    width: 70%;
    height: 550px;
}
.img-div>p{
    position: absolute;
    left: 10%;
    top: 5%;
    font-size: 20px;
    font-weight: 600;
    color: black;
}
.input-form{
    width: 100%;
    display: flex;
}
.input-form>div{
     background-color: #e3f1f6;
   margin-left: 15%;
    width: 450px;
    padding: 20px;
    box-shadow: 0px 0px 5px 6px rgba(0,0,0, 0.1);
    transform: translateY(-30%);
    border-radius: 5px;
}
.input-form>div>form{
    width: 100%;
}

.mp-form{
    position: relative;
}
.err-alert{
    position: fixed;
    top: 23%;
    right: 10px;
    border-radius: 6px;
    color: white;
    padding: 7px;
    font-size: 20px;
    transition: 0.5s;
}
.input-form +div{
    width: 90%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
}
.input-form +div>img{
    width: 100px;
    height: 100px;
    animation: moveUpBounce 15s linear infinite;
}
@keyframes moveUpBounce {
    0%,
    100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(0%, -50%);
    }
  
    80% {
      transform: translate(0%, -100%);
    }
  }
@media screen and (max-width:991px){
    .login-bar>img{
       display: none;
    }
    .img-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
    }
    .img-div>img{
        width: 70%;
        height: 500px;
        transform: translateX(-30px);
    }
    .img-div>p{
        font-size: 20px;
        font-weight: 600;
        color: black;
        margin: 20px 5%;
        text-align: center;
        position: static;
    }
    .input-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px;

    }
    .input-form>div{
       width: 50%;
       margin-left: 0%;
        transform: translateY(0%);
    }
}
@media screen and (max-width:800px){
    .img-div>img{
        width: 80%;
        height: 450px;
    }
    .input-form>div{
        width: 60%;
        margin-left: 0%;
         transform: translateY(0%);
     }
     .input-form +div{
        width: 80%;
    }
     .input-form +div>img{
        animation: moveLeftBounce 15s linear infinite;
    }
}
@media screen and (max-width:500px){
    .img-div>img{
        width: 90%;
        height: 400px;
        transform: translateX(-20px);
    }
    .input-form>div{
        width: 85%;
        margin-left: 0%;
         transform: translateY(0%);
     }
}