.footer{
    width: 100%;
    position: relative;
    background-color: #e3f1f6;
    overflow: hidden;
    display: grid;
    grid-template-columns: 20% 80%;
    color: #393939 !important;
    padding-top: 50px;
    font-family: Segoe UI Emoji;
}
.footer>img:first-child{
    position: absolute;
    top: -40px;
    width: 100%;
    height: 160px;
}

.footer>div:nth-child(2){
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 2;
    margin-top: 30%;
    position: relative;
    }

.footer>div:nth-child(2)>img:nth-child(1){
width: 170px;
height: 100px;
cursor: pointer;
}
.footer>div:nth-child(2)>img:nth-child(2){
    position: absolute;
    top:10%;
    left: 50%;
    width: 30px;
    height: 30px;
animation: move 10s linear infinite;
    }
    @keyframes move {
        0%,
        100% {
          transform: translate(0, 0);
        }
      
        50% {
          transform: translate(50px, -5%);
        }
      
        80% {
          transform: translate(80px, -10%);
        }
      }
.footer>div:nth-child(3){
   margin: 0px;
   /* width: 90%; */
   margin-left: 20%;
   grid-column-start: 2;
   grid-column-end: 3;
    }
.footer-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    
}
.footer-section>h4{
    margin: 20px 0px;
    font-size: 20px;
}
.footer-section>div{
    /* padding: 10px 80px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 14px;
}
.footer-section>div>div{
   font-size: 16px;
    
}
.footer-social{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-social>h4{
    margin: 20px 0px;
    font-size: 20px;
}
.footer-social>div{
    display: flex;
    justify-content: center;
    width: 100%;
      margin: 0px 0px 20px 0px ;
}
.footer-social>div:nth-child(2) img{
    filter: grayscale(100%);
 }
 .footer-social>div:nth-child(2) img:hover{
    filter: grayscale(0%);
 }
.footer-social>div:last-child{
   align-items: center;
}
.footer-social>div:last-child>img{
    filter: grayscale(100%);
 }

 .footer-social>div:last-child>img:hover{
    filter: grayscale(0%);
 }

.footer-social>div>img{
    margin: 0px 10px;
    cursor: pointer;
}
.footer-social>div>a{
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer a{
    color: #393939 !important
}
.footer a:hover{
    color: #0380b1 !important
}
.footer>div:last-child{
    grid-row: 2/3;
    background: #e3f1f6;
    grid-column: 1/3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #bcdae6; 
    margin: 0px 5%;
}
.footer>div:last-child>p{
    margin: 0px;
    font-size: 13px;
    padding: 10px 0px;
    
}

@media screen and (max-width:991px){
    .footer{
        grid-template-columns: 30% 70%;
    }

}
@media screen and (max-width:470px){
    .footer>div:nth-child(2)>img:nth-child(1){
        width: 130px;
        height: 70px;
        }
        .footer>div:nth-child(3){       
            margin-left: 0%;
             }
             .footer>div:last-child{
                margin: 0px 2%;
            }
            .footer>div:last-child>p{
                font-size: 11px;  
            }
}