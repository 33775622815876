.comp-welcome{
    width: 100%;
    height: 190px;
    background-color: #0380b1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.comp-welcome>h2{
    font-size: 37px;
}
.comp-welcome>div{
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.comp-welcome>div>a:first-child{
    color: #b3b3b3;
}
.comp-welcome>div>div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0px 2px;
    background-color: black;
}
.comp-welcome>div>a:last-child{
    color: #000;
}

.comp-welcome+img{
    width: 100%;
    padding-top: 20px;
}
.comp-para{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 20%;
    transform: translateY(-60px);
    position: relative;

}
.comp-para > h2 {
    color: #0380b1;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 20px;
  }
  .comp-para > div:nth-child(2) {
    color: #393939;
    font-size: 17px;
    font-family: Segoe UI Emoji;
    border: 2px solid rgba(0, 0, 0, 0.5);
    padding: 3%;
    border-radius: 5px;
    line-height: 32px;
    text-align: justify;
  }
  .comp-para > div:nth-child(2)>span {
    font-weight: 600;
  }
  .comp-para +div{
      width: 80%;
      height: 100px;
      display: flex;
      justify-content: flex-end;
  }
  .comp-para +div>img{
      width: 100px;
      height: 100px;
      animation: moveLeftBounce 15s linear infinite;
  }
  @media screen and (max-width:800px){
    .comp-para{
        padding: 0px 10%;
        transform: translateY(-20px);
    }
  }
