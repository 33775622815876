.tab-conge{
    position: relative;
}
.tab-conge form>button{
    width: 130px;
    margin: auto auto !important;
    padding: 12px;
    background-color: #0380b1 !important;
    color: white;
    border-radius: 10px;
    border: 1px solid transparent;
}
.tab-conge form>button:hover{
    color: #0380b1 !important;
    background-color: white !important;
border: 1px solid #0380b1;
}
.conge-alert{
    position: fixed;
    top: 94%;
    right: 10px;
    border-radius: 6px;
    background:  #37c95c;
    color: white;
    padding: 7px;
    font-size: 20px;
    transition: 0.5s;
}