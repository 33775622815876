.offre-welcome{
    width: 100%;
    height: 190px;
    background-color: #0380b1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.offre-welcome>h2{
    font-size: 37px;
}
.offre-welcome>div{
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.offre-welcome>div>a:first-child{
    color: #b3b3b3;
}
.offre-welcome>div>div{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0px 2px;
    background-color: black;
}
.offre-welcome>div>a:last-child{
    color: #000;
}
.img-offre{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}
.img-offre>img{
    width: 100%;
}
.img-offre>p{
    color: #393a42;
    position: absolute;
    top: 5%;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    padding: 0px 5px;
}
.offres-list{
    width: 100%;
    margin: 30px 0px;
}
.offres-list>div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
.offre-card{
    border-radius: 10px;
    
    width: 90%;
    height: 300px;
   
    position: relative;
    padding: 10px;
    margin: 20px 0px;
  
}
.offre-card>div:nth-child(2){
    position: absolute;
    inset: 0px;
    background-color: #e3f1f6;
    border-radius: 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 2;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
 
}
.offre-card>div:nth-child(2)>h2{
    color:#0380b1;
    font-weight: 700;
    font-size: 28px;
}
.offre-card:hover>div:nth-child(2){
     animation: moveUp 2s linear 1;
     box-shadow: none;
     transition: box-shadow 2s;
}
@keyframes moveUp{
    0%{
        transform: translateY(0%);
    }
    50%{
        transform: translateY(-40%);
    }
    100%{
        transform: translateY(0px);
    }
}
.offre-card .content{
    position: absolute;
    inset: 0px;
    line-height: 2;
    z-index: 1;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}
.offre-card>.content>div{
    border-bottom: 1px solid #0380b1;
}
.offre-card>.content>div>h2{
    padding-bottom: 0px;
    border-bottom: none;
    color:#0380b1 ;
    font-size: 22px;
    font-weight: 600;
    }
.offre-card:hover>div:nth-child(1){
    animation: moveDown 2s linear 1;
    z-index: 3;
    transition: z-index 2s,box-shadow 2s;
    box-shadow: 0px 0px 5px 6px rgba(114, 172, 197, 0.3);
}
@keyframes moveDown{
   0%{
       transform: translateY(0%);
   }
   50%{
       transform: translateY(40%);
   }
   100%{
       transform: translateY(0px);
   }
}

.offre-card .content a{
    padding: 7px 16px;
    color: white;
    background: #0380b1;
    border-radius: 8px;
font-size: 15px;
line-height: normal;
border: 1px solid transparent;
}
.offre-card .content p{
   margin-bottom: 5px;
}
.offre-card .content a:hover{
    color: #0380b1;
    background: white;
    border: 1px solid #0380b1;
}
.place-offre{
    position: absolute;
    right: 5%;
    bottom: 1%;
    color: rgb(68, 67, 67);
 
    z-index: 4;
}
.offre-card:hover .place-offre{
   animation: zoom 3s linear 1;
   color: #0380b1;
   transition: 6s;
}
.place-offre>i{
    margin-right: 5px;
}
@keyframes zoom{
    0%{
        transform: scale(1);
    }
    30%,40%,60%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}
.no-offre{
    color: #3d3d3d;
    font-size: 23px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    text-align: center;
    padding: 0px 5%;
}
.no-offre i{
    margin-right: 10px;
    font-size: 21px;
}
.spontanee{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 20px; */
}
.spontanee>a{
    padding: 6px 18px;
    color: white;
    background: #0380b1;
    border-radius: 10px;
font-size: 22px;
line-height: normal;
border: 1px solid #0380b1;
}
.spontanee>a:hover{
   color: #0380b1;
   background: white;
}
.spontanee +div{
    width: 80%;
    height: 100px;
    display: flex;
    justify-content: flex-end;
}
.spontanee +div>img{
    width: 100px;
    height: 100px;
    animation: moveLeftBounce 15s linear infinite;
}
@media screen and (max-width:850px){
    .img-offre>p{
       font-size: 20px;
    }
}
@media screen and (max-width:570px){
    .img-offre>p{
       font-size: 15px;
    }
}
@media screen and (max-width:420px){
    .img-offre>p{
       font-size: 12px;
    }
    .no-offre{
        font-size: 20px;
    }
}