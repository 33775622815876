*{
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth !important
}

body {
  margin: 0;
    font-family: Franklin Gothic Demi !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  
}

